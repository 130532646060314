// import React from "react";
// import {
// 	Switch,
// 	Route,
// 	Link,
// 	useHistory,
// 	useRouteMatch,
// } from "react-router-dom";
// import { Button } from "antd";

// export default function Playground() {
// 	let history = useHistory();
// 	let match = useRouteMatch();

// 	const handleClick = () => {
// 		console.log(match);
// 	};

// 	return (
// 		<div>
// 			<Button type="primary" onClick={handleClick}>
// 				Click Me
// 			</Button>
// 		</div>
// 	);
// }

import React from "react";

export default function Playground() {
	return (
		<div>
			<h1>this is playground</h1>
		</div>
	);
}
